import React, { useEffect, useState } from "react";
import Search from "./Search";
import logo from "../assets/images/logo.png";
import whatsappicon from "../assets/images/whatsappicon.png";
import phoneIcon from "../assets/images/phone-Icon.png";

const menuItem = [
  {
    menu: "Home",
    link: "/",
  },
  {
    menu: "About us",
    link: "/about",
  },
  {
    menu: "Product",
    link: "/product",
  },
  {
    menu: "Contact Us",
    link: "/contact",
  },
];
const Header = ({ onClick, search }) => {
  const [menu, setMenu] = useState("/");

  useEffect(() => {
    setMenu(window.location.pathname);
  }, []);

  return (
    <>
      <div class="whatsappbtn">
        <a href="https://wa.me/7090702000" target="_blank">
          <img width="32" height="32" src={whatsappicon} alt="Whatsapp" />
        </a>
      </div>
      <div class="phonebtn">
        <a href="tel:7090702000" target="_blank">
          <img width="32" height="32" src={phoneIcon} alt="Phone" />
        </a>
      </div>
      <header class="header_container">
        <div class="container">
          <nav class="navbar navbar-expand-lg">
            {/* <div class="inner d-flex flex-row flex-wrap align-items-center animatedParent animateOnce"> */}
            <div class="inner d-flex flex-row flex-wrap align-items-center animatedParent animateOnce">
              {/* <div class="logo animated fadeInLeft"> */}
              <div class="logo animated fadeInLeft">
                <a href="/">
                  <img width="175" height="54" src={logo} alt="Logo" />
                </a>
              </div>
              <div class="nav-right">
                <button
                  class="navbar-toggler animated fadeIn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div
                  class="main_nav collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <div class="d-flex flex-column flex-lg-row flex-wrap align-items-md-center justify-content-end">
                    <div class="menu_wrap animated fadeIn">
                      <ul>
                        {menuItem.map((result) => {
                          return (
                            <li class={result.link === menu ? "active" : ""}>
                              <a href={result.link}>{result.menu}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div>
                      <i class="bi bi-telephone-fill"></i> +91 7090702000
                    </div>
                  </div>
                </div>
                <Search onClick={(value) => onClick(value)} search={search} />
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;

import { useState } from "react";
import * as XLSX from "xlsx";
import { chandExcelDelete, chandExcelCreate } from "../api/fetchApi";

function XlsFileupload() {
  const [data, setData] = useState([]);
  const [statusbar, setStatusbar] = useState("");

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      setData(parsedData);
    };
  };

  const uploadRecords = () => {
    const truncateexcel = async () => {
      setStatusbar("Deleting The Records....");
      await chandExcelDelete()
        .then(async (response) => {
          setStatusbar("Records Deleted....");
          console.log(response);
          setStatusbar("Inserting Records....");

          for (let index = 2; index < data.length; index++) {
            setStatusbar(
              "Inserting Records...." + index + " of " + data.length
            );
            const element = data[index];
            // console.log(element);

            const formData = new FormData();
            formData.append("cdetail", element["AK WINES"]);
            formData.append("Cbrnnam", element["__EMPTY"]);
            formData.append("category", element["__EMPTY_1"]);
            formData.append("subcategory", element["__EMPTY_2"]);
            formData.append("subsubcategory", element["__EMPTY_3"]);
            formData.append("grape", element["__EMPTY_4"]);
            formData.append("country", element["__EMPTY_5"]);
            formData.append("region", element["__EMPTY_6"]);
            formData.append("item", element["__EMPTY_7"]);
            formData.append("code", element["__EMPTY_8"]);
            formData.append("ml", element["__EMPTY_9"]);
            formData.append("qty", element["__EMPTY_10"]);
            formData.append("salerate", element["__EMPTY_11"]);
            formData.append("rate", element["__EMPTY_12"]);
            formData.append("webcode", element["__EMPTY_13"]);
            // console.log(data1);

            await fetch("https://akwines.in/products/exceltableinsert", {
              method: "post",
              body: formData,
            })
              .then((response) => {
                setStatusbar("Records Insertion Done");
                console.log(response);
              })
              .catch((error) => {
                console.log("Error: Insertion");
                console.log(error);
              });
            setStatusbar("Insertion Completed");
          }
        })
        .catch((error) => {
          console.log("Error: Deletion");
          console.log(error);
        });
    };
    truncateexcel();
  };

  return (
    <div className="App">
      <br />
      <h4>Status: {statusbar}</h4>
      <br />
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      &nbsp; &nbsp; &nbsp;
      <button
        className="btn btn-primary"
        onClick={() => {
          if (window.confirm("!!!Overwrite the old records with this new ?")) {
            uploadRecords();
          }
        }}
      >
        Upload Data
      </button>
      <br />
      {data.length > 0 && (
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              {Object.keys(data[0]).map((key) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, index) => (
                  <td key={index}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <br />
      <br />
    </div>
  );
}

export default XlsFileupload;
